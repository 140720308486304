.app-header {
    display: flex;
    flex-basis: 100%;
    z-index: 99;
    height: 100px;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
    .header-content {
        display: flex;
        flex-wrap: wrap;
        flex-basis: 100%;
        padding: 20px;
        justify-content: space-between;
        align-items: center;
        .navigation {
            display: flex;
            min-height: 40px;
            align-items: center;
            .logo {
                margin-right: 50px;
                img {
                    height: 30px;
                }
            }
            .nav-item {
                border-radius: 5px;
                border:  solid 2px #1C244B;
                text-decoration: none;
                min-height: 40px;
                padding: 0 20px;
                display: flex;
                align-items: center;
                margin-right: 10px;
                color: #1C244B;
                font-weight: 800;
                img {
                    height: 20px;
                    margin-right: 10px;
                }
                &:hover{
                    background-color: #1C244B;
                    color: whitesmoke;
                    transition: all 0.2s;
                }
            }
        }
        .logout {
            display: flex;
            align-items: center;
            .primary-btn {
                min-height: 40px;
                padding: 0 20px;
                margin-left: 10px;
            }
        }
    }
}