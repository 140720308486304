.btn {
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.sorter-container {
  position: relative;
  display: flex;
  align-items: center;
}

.export-btn {
  position: absolute;
  top: 250px;
  right: 100px;
}

.range-txt {
  margin-bottom: 10px;
  font-weight: 700;
  margin-right: 20px;
}

.range-picker {
  display: flex;
  align-items: center;


  input {
    margin-right: 10px;
    height: 22px;
    padding: 2px;

    font-weight: 500;
    margin-bottom: 10px;
  }
}