.subscribe {
    display: flex;
    margin: 0px 20px;
    padding: 20px;
    background-color: #fff;
    flex-basis: 100%;
    border-radius: 5px;

    flex-wrap: wrap;
    .subscribe-content {
        flex-basis: 100%;
        max-width: 1200px;
        margin: 30px auto;
        h1 {
            display: flex;
            align-items: center;
            font-weight: 700;
            margin-bottom: 30px;
        }
        img {
            height: 35px;
        }
    }
}
.separator {
    flex-basis: 100%;
}
.validation-group {
    display: flex;
    flex-basis: 100%;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
}
.checkbox-wrapper {
    width: 50%;
    text-align: end;
}
.validation {
    margin: 50px 0;
    h2 {
        font-size: 20px;
    }
    .validation-btn {
        padding: 10px 20px;
        font-size: 16px;
        margin-top: 20px;
    }
}
.MuiFormControl-root {
    margin-bottom: 20px !important;
}
.factu {
    display: flex;
    flex-basis: 100%;
    padding-left: 25px;
}
.MuiButton-endIcon img {
    height: 20px !important;
}
.MuiFormControlLabel-label {
    background-color: #1C244B;
    color: #fff;
    font-weight: 600;
    font-size: 16px;
    border-radius: 50px;
    padding: 10px 20px;
    margin-top: 20px !important;
}
.MuiButton-root {
   background-color: #1C244B !important;
   align-self: flex-end;
}