.login {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .login-content {
    display: flex;
    flex-wrap: wrap;
    width: 400px;
    background-color: white;
    justify-content: center;
    padding-bottom: 50px;

    border-radius: 10px;
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);


    .form-cmd {
      display: flex;
      flex-wrap: wrap;
      flex-basis: 100%;

      > a {
        flex-basis: 100%;
      }

      .primary-btn {
        flex-basis: 100%;
        min-height: 40px;

      }
    }

    > div {
      display: flex;
      flex-basis: 100%;
      justify-content: center;
      margin: 40px 0;

      img {
        height: 30px;
      }
    }

    .sign-in-content {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      h1 {
        display: flex;
        justify-content: center;
        flex-basis: 100%;
        margin-bottom: 10px;
        font-weight: 600;
        margin-bottom: 30px;
        font-size: 26px;
      }

      .input-wrapper {
        margin-bottom: 10px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;

        label {
          display: flex;
          width: 80px;
          margin-bottom: 10px;
          font-weight: 400;

        }

        input {
          border: none;
          height: 40px;
          border-radius: 5px;
          width: 98%;
          padding-left: 10px;
          background-color: transparent;
          outline: solid 1px rgb(181, 179, 179);
          max-width: 233px;

          &::placeholder {
            font-style: italic;
            color: lightgray;
          }
        }
      }

      #submit-button {
        margin: 20px auto;
        height: 30px;
        cursor: pointer;

        margin-bottom: 20px;
      }

    }

  }
}

.forgot-password {
  font-size: 12px;
  position: relative;
  top: 20px;
}

.password {
  position: relative;

  label {
    min-width: fit-content;
  }

  .show-password-btn {
    position: absolute;
    background-color: transparent;
    border: none;
    top: 39px;
    left: 215px;
    cursor: pointer;

    img {
      height: 15px;
    }
  }
}