.userList {
    min-height: 90vh;

    flex-basis: 100%;
    flex-wrap: wrap;
    max-width: 1300px;
    margin: 50px auto;
    align-items: flex-start;
    h1 {
        flex-basis: 100%;
        margin-bottom: 50px;
    }

    .user-list_container {
        display: flex;
        flex-basis: 100%;

    }
    .search-bar {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
    }
    .search-title {
        
        font-weight: bold;
        margin-right: 30px;
    }
    
}