//Fonts

// --> Poppins --> Titles 
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
// --> Nunito --> Body
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&display=swap');
//Bases 

body {
    font-family: 'Nunito Sans', sans-serif;
}
h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', sans-serif;
}
.primary-btn {
    border: none;
    border-radius: 5px;
    background-color: #1C244B;
    font-weight: bold;
    color: whitesmoke;
    cursor: pointer;
}
.secondary-btn {
    border-radius: 5px;
    background-color: #CF4526;
    font-weight: bold;
    color: whitesmoke;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}
h1 {
    font-family: 'Poppins', sans-serif;
    font-weight: 800;
    color: #1C244B;
    font-size: 36px;
}


// not display app under 1200px
@media screen and (min-width : 700px) {
    .not-displayed {
        display: none;
    }
}
@media screen and (max-width : 700px) {
    .not-displayed {
        position: fixed;
        width: 100vw;
        height: 100vh;
        z-index: 9999;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #F5F8FF;
        padding: 50px;
        > #mobile-message{
            position: relative;
            bottom: 300px;
            flex-basis: 50%;
            text-align: center;
        }
    }
}

// app rules main container 
.App {
    display: flex;
    width: 100%;
    overflow: hidden;
    background-color: whitesmoke;
    min-height: 100vh;
    flex-wrap: wrap;
}
.error-message {
    font-size: 10px;
    color: red;
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    text-align: center;
}
.font-medium {
    font-size: 18px;
    position: relative;
    left: 155px;
}
.item-dev {
    margin-bottom: 20px;
}

.phoneError {
    display: flex;
    flex-basis: 100%;
    position: absolute;
    bottom: -13px;
    left: 290px;
    font-size: 12px;
    color: red;
}
.input-phone {
    position: relative;
}
.datepicker {
    max-width: 150px;
}
.datepicker-wraper {
    display: flex;
    justify-content: flex-start;
    min-width: 68% !important;
}
.border-red {
    border: solid 1px red;
}
.item-infos {
    display: flex;
    margin-bottom: 10px;
    p:first-child {
        margin-right: 10px;
        font-weight: bold;
    }
    p:nth-child(2) {
        padding: 2px 5px;
        background-color: whitesmoke;
        border-radius: 5px;
    }
}
.checkbox-wrapper {
    margin-top: 30px;
    input {
        margin-right: 20px;
    }
}
.red {
    background-color: red !important;
}
.sign-in-content {
    position: relative;
}
.hotline {
    position: fixed;
    top: 20px;
    right: 50px;
    border-radius: 5px;
    background-color: #CF4526;
    font-weight: bold;
    color: whitesmoke;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding: 0 20px;
}