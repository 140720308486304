.step {
    display: flex;
    flex-basis: 100%;
    background-color: #FFFF;
    border-radius: 5px;

    margin-top: 70px;
    position: relative;
    padding: 100px  40px 50px 40px ;
    

    .MuiInputLabel-shrink {
        background-color: #ffffff; 
        padding: 0 4px; 
        transform: translate(14px, -6px) scale(0.75); 
    }
    .user-infos_form {
        flex-basis: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

    }
    .step-title {
        background-color: #CF4526;
        color: whitesmoke;
        font-size: 22px;
        position: absolute;
        font-weight: 600;
        border-radius: 50px;
        top: -30px;
        left: 30px;
        padding: 15px 30px ;
        > span {
            font-size: 26px;
            font-weight: 800;
        }
        
    }
    .column {
        
        flex-basis: 100%;
    }
    .d-flex {
        display: flex;
    }
    .al-item-center {
        align-items: center;
    }
    .just-sp-between  {
        justify-content: space-between;
    }

    .names {
        display: flex;
        align-items: center;
        margin: 20px 0;
        .css-wb57ya-MuiFormControl-root-MuiTextField-root, .css-17vbkzs-MuiFormControl-root-MuiTextField-root, .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root {
            margin: 0 20px 0 0 !important;
        }
        .css-17vbkzs-MuiFormControl-root-MuiTextField-root {
            margin-right: 20px;
        }
    }
    label {
        margin-right: 20px;
        font-weight: bold;
        font-size: 16px;
        text-align: end;
        flex-basis: 30%;

    }

    .sign-btn {
        display: flex;
        min-height: 40px;
        padding: 0 20px;
        margin-left: auto;
        align-items: center;
        margin-top: 30px;
        cursor: pointer;
        img {
            height: 10px;
            margin-left: 10px;
        }
    }
    .subtitle {
        font-weight: bold;
        margin-bottom: 30px;
        font-size: 19px;
    }
    
} 
.bidthdate {
    margin-right: 20px;
}

.gender {
    display: flex;
    flex-direction: row;
    align-items: center;

    
    .MuiFormGroup-root {
        flex-basis: 100% !important;
    }

    // Si vous voulez ajouter de l'espace entre les boutons radio
    .MuiFormControlLabel-root {
        margin-right: 20px; // ajustez selon vos besoins
        flex-basis: 26%;



    }
}
.css-dmmspl-MuiFormGroup-root {
    flex-direction: row !important;
}
.civ {
    font-weight: bold !important;
    color: rgb(110, 108, 108);
    position: relative;
    left: 9px;
}
.civ-comp {
    border: solid 1px lightgray !important;
    border-radius: 5px;
    position: relative;
    bottom: 4px;
    height: 60px;
}
.shop-info {
    display: flex;
    width: 50%;
    flex-direction: row;
    margin-bottom: 50px;
    .input-wraper {
        flex-basis: 50%;
        &:first-child {
            margin-right: 20px;
        }
    }
}
.m-20 {
    margin: 20px 0;
}
.price label{
    flex-basis: 45%;


}
.price .MuiFormGroup-root {
    min-width: 600px;

}
.price .css-ahj2mt-MuiTypography-root  {
    font-weight: 700 !important;
    background-color: #1C244B;
    border-radius: 50px;
    padding: 10px 20px;
    color: white;
}
.location .MuiFormControl-root {
    margin-right: 20px !important;
    flex-basis: 30%;
    &:nth-child(3) {
        flex-basis: 20%;
    }
    &:nth-child(4) {
        flex-basis: 20%;
        margin: 0;
 
    }
}
.contact-info {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 20px;
}
.contact-info .input-wrapper {
    margin-right: 20px;
    flex-basis: 24%;
}
.offers {
    margin-top: 30px; 
    flex-basis: 100%;

    legend{
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 20px;
    }
}
.css-1nrlq1o-MuiFormControl-root {
    padding-left: 10px !important;
}

.yousign-iframe {
    width: 100%;
    height: 800px;

    iframe {
        width: 100%;
        height: 100%;
    }
}