.progress-bar {
  display: flex;
  align-items: center;
  flex-basis: 80%;
  margin: 50px auto;
  margin-top: 80px;

  .circle, .line {
    transition: all 0.5s;
  }

  .circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e0e0e0;
    color: #aaa;
    font-weight: bold;
    position: relative;
    font-size: 20px;

    &.active {
      background-color: #CF4526; // Couleur pour l'étape active
      color: white;
    }

    &.passed {
      background-color: #1C244B; // Couleur verte pour les étapes déjà passées
      color: white;
    }

    .title-user-info {
      position: absolute;
      min-width: 350px;
      left: -15px;
      top: -30px;
      color: #1C244B;
      text-transform: uppercase;
      font-weight: 700;

      &.one {
        left: -32px;
      }
    }
  }

  .line {
    flex-grow: 1;
    height: 5px;
    background-color: #e0e0e0;

    &.active {
      background-color: #1C244B; // Couleur pour l'étape active
    }
  }
}
